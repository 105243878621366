<template>
<div class="container-fluid px-0">
	<div class="post-content" v-if="loaded">
		<div class="post-container p-4">
			<div class="post-detail">
				<div class="user-info">
					<h5><router-link :to="{ name: 'profile', params: { id: author.id } }" class="profile-link">{{ author.username }}</router-link></h5>
					<p class="text-muted">{{ postedTimeMessage }}</p>
				</div>
				<div class="reaction">
					<div class="float-start">
						<vote-buttons :upvotes="upvotes" :upvoted="upvoted" @addUpvote="upvotePost()" @removeUpvote="removeUpvote()"></vote-buttons>
					</div>
				</div>
				<div class="line-divider"></div>
				<div class="post-title align-middle mb-4">
					<p>{{ title }}</p>
				</div>
				<div class="line-divider"></div>
				<div class="post-text">
					<p>{{ content }}</p>
				</div>
				<div class="line-divider"></div>
				<div class="comments ms-5">
					<comment v-for="comment in comments" :key="comment.id"
						:authorId="comment.author"
						:upvotes="comment.upvotes"
						:upvoteList="upvoteList"
						:content="comment.content"
						:createdAt="comment.createdAt"
						:id="comment.id"
						:postId="id"
					>
					</comment>
				</div>
			</div>
			<form class="post-comment pt-4" @submit.prevent="createComment()">
				<input type="text" class="form-control" placeholder="Post a comment" v-model="commentContent">
				<div class="form-text text-danger" v-if="errorMessage">
					{{ errorMessage }}
				</div>
			</form>
		</div>
	</div>
</div>
</template>

<style lang="scss" scoped>
@import "../variables.scss";

.post-content {
  background: lighten($color-background, 5);
  border-radius: 6px;
  width: 100%;
  border: 1px solid darken($color-background, 10);
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}

.post-title {
	font-size: 30px;
}

.post-content .post-container .post-detail .post-text {
  line-height: 24px;
  margin: 0;
}

.post-content .post-container .post-detail .post-comment {
  display: inline-flex;
  margin: 10px auto;
  width: 100%;
}

.post-content .post-container .post-detail .post-comment .form-control {
  height: 30px;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  margin: 7px 0;
  min-width: 0;
}
</style>

<script>
export default {
	data() {
		return {
			loaded: false,
			author: null,
			commentContent: "",
			errorMessage: null
		};
	},
	async created() {
		await this.resolveAuthor();
		this.loaded = true;
	},
	methods: {
		async resolveAuthor() {
			this.author = await this.$store.dispatch("users/getUserById", this.authorId);
		},
		upvotePost() {
			this.$store.dispatch("feed/upvotePost", this.id);
		},
		removeUpvote() {
			this.$store.dispatch("feed/removePostUpvote", this.id);
		},
		async createComment() {
			const result = await this.$store.dispatch("feed/createComment", {
				postId: this.id,
				content: this.commentContent
			});

			if(result.error) {
				this.errorMessage = result.error;
			} else {
				this.commentContent = "";
				this.errorMessage = null;
			}
		}
	},
	props: {
		authorId: {
			type: String,
			required: true
		},
		id: {
			type: String,
			required: true
		},
		title: {
			type: String,
			required: true
		},
		content: {
			type: String,
			required: true
		},
		createdAt: {
			type: String,
			required: true
		},
		comments: {
			type: Array,
			required: true
		},
		upvotes: {
			type: Number,
			required: true
		},
		upvoteList: {
			type: Set,
			require: true
		}
	},
	computed: {
		upvoted() {
			return this.upvoteList.has(this.id);
		},
		postedTimeMessage() {
			const createdDate = new Date(this.createdAt);
			const now = new Date();

			const daysPassed = now.getDay() - createdDate.getDay();
			const minutes = createdDate.getMinutes().toString().padStart(2, "0");
			const hour = createdDate.getHours() % 12 || 12;
			const meridiem = createdDate.getHours() < 12 ? "AM" : "PM";

			if(daysPassed === 0) {
				return `Today at ${hour}:${minutes} ${meridiem}`;
			} else if(daysPassed === 1) {
				return `Yesterday at ${hour}:${minutes} ${meridiem}`;
			} else {
				return `${createdDate.getMonth() + 1}/${createdDate.getDay() + 1}/${createdDate.getFullYear()} at ${hour}:${minutes} ${meridiem}`;
			}
		}
	}
};
</script>
